import { memo, useEffect, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
// import CreateUi from "./CreateUi";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
// import {
//   addTopicApi,
//   getTopicByIdApi,
//   updateTopicField,
// } from "../../apis/topic.api";
import { closeModal } from "../../store/actions/modalAction";
import { useParams } from "react-router-dom";
import { SNACK_BAR_VARIETNS, USER_ROLES } from "../../utils/constants";
import { callSnackBar } from "../../store/actions/snackbarAction";
import {
  addvendorApi,
  getVendorByIdApi,
  updatevendorApi,
} from "../../apis/vendor.api";
import CreatevendorUI from "./CreatevendorUI";
import { fetchVendorAction } from "../../store/actions/setting.Action";

const CreateVendorController = ({ callBack, id, role = null }) => {
  const validate = useValidate();
  const dispatch = useDispatch();
  const params = useParams();
  const modalKey = "vendor";
  const title = "Vendor";
  const createApi = addvendorApi;
  const updateApi = updatevendorApi;
  const getByIdApi = getVendorByIdApi;
  const { settings } = useSelector((state) => state);

  const [loading, setLoading] = useState(false);
  const isRolePredefined = role !== null;
  const [fields, setFields] = useState({
    err: "",
    id,
    name: "",
    email: "",
    country: "",
    gst_no: "",
    rawMaterials: [
      {
        rawMaterialId: { _id: "", name: "" },
        min_of_quantity: "",
      },
    ],
    address: "",
    phone: "",
  });
  const [originalDocument, setOriginalDocument] = useState({});

  const validationSchemaForCreate = useMemo(
    () =>
      [
        {
          required: true,
          value: fields.name,
          field: "name",
        },
        {
          required: true,
          value: fields.address,
          field: "address",
        },
        
        {
          required: true,
          value: fields.phone,
          field: "phone",
        },

        {
          required: true,
          value: fields.country,
          field: "name",
        },

      ],
    [fields]
  );

  const validationSchemaForUpdate = useMemo(
    () => [
      {
        required: true,
        value: fields.name,
        field: "name",
      },

      // {
      //   required: true,
      //   value: fields.email,
      //   field: "Email",
      // },

      {
        required: true,
        value: fields.address,
        field: "address",
      },
      // {
      //   required: true,
      //   value: fields.phone,
      //   field: "phone",
      // },
      {
        required: true,
        value: fields.country,
        field: "Country",
      },
    ],
    [fields]
  );

  const createFunction = async () => {
    const validationResponse = validate(validationSchemaForCreate);

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await createApi(fields),
          async (response) => {
            setLoading(false);
            dispatch(
              callSnackBar(
                "Vendor Created Successfully",
                SNACK_BAR_VARIETNS.suceess
              )
            );
            dispatch(fetchVendorAction(settings.vender_filters));
            dispatch(closeModal(modalKey));
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const updateFunction = async () => {
    const validationResponse = validate(validationSchemaForUpdate);
    const updatedData = { id };

    for (let field in originalDocument) {
      if (
        originalDocument[field] &&
        fields[field] &&
        fields[field] != originalDocument[field]
      ) {
        updatedData[field] = fields[field];
      }
    }

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await updateApi(updatedData),
          async (response) => {
            await callBack(response, updatedData);
            setLoading(false);
            dispatch(
              callSnackBar(
                "Vendor Updated Successfully",
                SNACK_BAR_VARIETNS.suceess
              )
            );
            dispatch(fetchVendorAction(settings.vender_filters));
            dispatch(closeModal("vendorupdate"));
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (id) updateFunction();
    else createFunction();
  };

  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getByIdApi({ id }),
        async (response) => {
          setFields({ ...fields, ...response });
          setOriginalDocument(response);
          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (id) fetchById(id);
  }, [id]);

  return (
    <CreatevendorUI
      isRolePredefined={isRolePredefined}
      modalKey={modalKey}
      title={title}
      isUpdate={id}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  );
};
export default memo(CreateVendorController);
