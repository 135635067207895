export const USER_ROLES = {
  ADMIN: 1,
  ACCOUNT: 2,
  PURCHASE: 3,
  DESIGN_TEAM: 4,
  PRODUCTION: 5,
  PACKAGING_AND_DISPATCH: 6,
  ASSEMBLY: 7,

};

export const actions = {
  SIGN_IN: 'SIGN_IN',
  SET_USER: 'SET_USER',
  SIGN_OUT: 'SIGN_OUT',
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  UPDATE_USER_DATA: 'UPDATE_USER_DATA',
  SET_USER_LAST_READ_NOTIFICATION: 'SET_USER_LAST_READ_NOTIFICATION',
  CALL_SNACKBAR_DATA: "CALL_SNACKBAR_DATA",
  SET_NOTIFICATION_DATA: "SET_NOTIFICATION_DATA",
  NOTIFICATION_READ: "NOTIFICATION_READ",

  FETCH_DATA_VENDER_LODING: "FETCH_DATA_VENDER_LODING",
  FETCH_DATA_VENDER_DATA: "FETCH_DATA_VENDER_DATA",
  FETCH_DATA_VENDER_DELETE: "FETCH_DATA_VENDER_DELETE",

  FETCH_DATA_RAWMATERIAL_LODING: "FETCH_DATA_RAWMATERIAL_LODING",
  FETCH_DATA_RAWMATERIAL_DATA: "FETCH_DATA_RAWMATERIAL_DATA",
  FETCH_DATA_RAWMATERIAL_DELETE: "FETCH_DATA_RAWMATERIAL_DELETE",

  FETCH_DATA_PRODUCT_LODING: "FETCH_DATA_PRODUCT_LODING",
  FETCH_DATA_PRODUCT_DATA: "FETCH_DATA_PRODUCT_DATA",
  FETCH_DATA_PRODUCT_DELETE: "FETCH_DATA_PRODUCT_DELETE",

  FETCH_DATA_DEPARTMENT_LODING: "FETCH_DATA_DEPARTMENT_LODING",
  FETCH_DATA_DEPARTMENT_DATA: "FETCH_DATA_DEPARTMENT_DATA",
  FETCH_DATA_DEPARTMENT_DELETE: "FETCH_DATA_DEPARTMENT_DELETE",

  FETCH_DATA_TYPE_OF_SALES_LODING: "FETCH_DATA_TYPE_OF_SALES_LODING",
  FETCH_DATA_TYPE_OF_SALES_DATA: "FETCH_DATA_TYPE_OF_SALES_DATA",
  FETCH_DATA_TYPE_OF_SALES_DELETE: "FETCH_DATA_TYPE_OF_SALES_DELETE",

  FETCH_DATA_CATEGORY_LODING: "FETCH_DATA_CATEGORY_LODING",
  FETCH_DATA_CATEGORY_DATA: "FETCH_DATA_CATEGORY_DATA",
  FETCH_DATA_CATEGORY_DELETE: "FETCH_DATA_CATEGORY_DELETE",

  FETCH_DATA_UPDATE_STOCK_PRODUCT_LODING: "FETCH_DATA_UPDATE_STOCK_PRODUCT_LODING",
  FETCH_DATA_UPDATE_STOCK_PRODUCT_DATA: "FETCH_DATA_UPDATE_STOCK_PRODUCT_DATA",
  FETCH_DATA_UPDATE_STOCK_PRODUCT_DELETE: "FETCH_DATA_UPDATE_STOCK_PRODUCT_DELETE",

  FETCH_DATA_UPDATE_STOCK_RAW_MATERIAL_LODING: "FETCH_DATA_UPDATE_STOCK_RAW_MATERIAL_LODING",
  FETCH_DATA_UPDATE_STOCK_RAW_MATERIAL_DATA: "FETCH_DATA_UPDATE_STOCK_RAW_MATERIAL_DATA",
  FETCH_DATA_UPDATE_STOCK_RAW_MATERIAL_DELETE: "FETCH_DATA_UPDATE_STOCK_RAW_MATERIAL_DELETE",

  FETCH_DATA_QUALITY_CHECK_LODING: "FETCH_DATA_QUALITY_CHECK_LODING",
  FETCH_DATA_QUALITY_CHECK_DATA: "FETCH_DATA_QUALITY_CHECK_DATA",
  FETCH_DATA_QUALITY_CHECK_DELETE: "FETCH_DATA_QUALITY_CHECK_DELETE",

  FETCH_BATCH_LODING:'FETCH_BATCH_LOADING',
  FETCH_BATCH_DATA:'FETCH_BATCH_DATA',
  FETCH_BATCH_DELETE:'FETCH_BATCH_DELETE',

  FETCH_DATA_PRODUCT_BRAND_LODING: "FETCH_DATA_PRODUCT_BRAND_LODING",
  FETCH_DATA_PRODUCT_BRAND_DATA: "FETCH_DATA_PRODUCT_BRAND_DATA",
  FETCH_DATA_PRODUCT_BRAND_DELETE: "FETCH_DATA_PRODUCT_BRAND_DELETE",

  FETCH_DATA_PRODUCT_COLOR_LODING: "FETCH_DATA_PRODUCT_COLOR_LODING",
  FETCH_DATA_PRODUCT_COLOR_DATA: "FETCH_DATA_PRODUCT_COLOR_DATA",
  FETCH_DATA_PRODUCT_COLOR_DELETE: "FETCH_DATA_PRODUCT_COLOR_DELETE",

  FETCH_DATA_PRODUCT_TYPE_LODING: "FETCH_DATA_PRODUCT_TYPE_LODING",
  FETCH_DATA_PRODUCT_TYPE_DATA: "FETCH_DATA_PRODUCT_TYPE_DATA",
  FETCH_DATA_PRODUCT_TYPE_DELETE: "FETCH_DATA_PRODUCT_TYPE_DELETE",

  FETCH_COMPONENT_LODING: "FETCH_COMPONENT_LODING",
  FETCH_COMPONENT_DATA: "FETCH_COMPONENT_DATA",
  FETCH_COMPONENT_DELETE: "FETCH_COMPONENT_DELETE",

  FETCH_ITEM_TYPE_LODING: "FETCH_ITEM_TYPE_LODING",
  FETCH_ITEM_TYPE_DATA: "FETCH_ITEM_TYPE_DATA",
  FETCH_ITEM_TYPE_DELETE: "FETCH_ITEM_TYPE_DELETE",


}


export const NOTIFICATION_TYPE = {
  general: "general",
  lead: "lead",
};

export const YES_NO = {
  Pass: true,
  Reject: false,
}

export const SNACK_BAR_VARIETNS = {
  error: "error",
  suceess: "success",
  info: "info",
  warning: "warning",
};

export const DAY_WEEK_MONTH = {
  DAY: "day",
  WEEK: "week"
}

export const LOG_TYPE = {
  In: 1,
  Out: -1
}

export const DATE_TIME_FILTERS = {
  this_week: "This Week",
  this_month: "This Month",
  this_year: "This Year",
  last_month: "Last Month",
  last_year: "Last Year",
  last_week: "Last Week",
  today: "Today",
  yesterday: "Yesterday",
};