import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomInput from "../../components/inputs/CustomInputs";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { StyledSearchBar } from "../../components/inputs/SearchBar";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import { fetchRawMaterialApi } from "../../apis/rawMaterial.api";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AddMaterialUi from "./AddMaterialUi";
import COUNTRY_CODES from "../../utils/country";
const CreatevendorUI = ({
  title,
  modalKey,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
  index,
}) => {
  const { user } = useSelector((state) => state);

  const [optiondata, setOptiondata] = useState();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <>
      <CustomDialog
        id={`${isUpdate ? "vendorupdate" : modalKey}`}
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${isUpdate ? "Update" : "Create"} ${title}`}
        closeText="Close"
        confirmText={`${isUpdate ? "Update" : "Create"}`}
      >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            <CustomInput
              autoFocus={true}
              disabled={loading}
              value={fields.name}
              onChange={(e) =>
                setFields({ ...fields, err: "", name: e.target.value })
              }
              type="text"
              label={"Vendor Name*"}
            />

            <CustomInput
              disabled={loading}
              value={fields.address}
              onChange={(e) =>
                setFields({ ...fields, err: "", address: e.target.value })
              }
              type="text"
              label={"Address*"}
            />

            <CustomInput
              disabled={loading}
              value={fields.email}
              onChange={(e) =>
                setFields({ ...fields, err: "", email: e.target.value })
              }
              type="text"
              label={"Email"}
            />

            <CustomInput
              disabled={loading}
              value={fields.phone}
              onChange={(e) =>
                setFields({ ...fields, err: "", phone: e.target.value })
              }
              type="text"
              label={"Phone*"}
            />

            <Autocomplete
              id="country-select-demo"
              sx={{ width: "100%", mt: 2 }}
              options={COUNTRY_CODES}
              autoHighlight
              getOptionLabel={(option) => option.name}
              value={fields.country ? { name: fields.country } : null}
              onChange={(event, newValue) => {
                setFields({
                  ...fields,
                  err: "",
                  country: newValue?.name || "",
                });
              }}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                  <Box key={key} component="li" {...optionProps}>
                    {option.name}
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Country*"
                  slotProps={{
                    htmlInput: {
                      ...params.inputProps,
                      autoComplete: "new-password",
                    },
                  }}
                />
              )}
            />

            {fields.country == "India" && (
              <Box mt={2}>
                <CustomInput
                  disabled={loading}
                  value={fields.gst_no}
                  onChange={(e) =>
                    setFields({ ...fields, err: "", gst_no: e.target.value })
                  }
                  type="text"
                  label={"GST Number*"}
                />
              </Box>
            )}

            {isUpdate && (
              <>
                <Box>
                  <AddMaterialUi
                    fields={fields}
                    setFields={setFields}
                    index={index}
                  />
                </Box>

                <Box
                  mt={2}
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setFields((data) => {
                        let arr = [...data.rawMaterials];
                        arr.push({
                          rawMaterialId: { _id: null, name: "" },
                          min_of_quantity: null,
                        });
                        return { ...data, rawMaterials: arr };
                      });
                    }}
                  >
                    <Typography variant="h6">Add More Materials</Typography>
                  </Button>
                </Box>
              </>
            )}
          </>
        )}
      </CustomDialog>
    </>
  );
};

export default memo(CreatevendorUI);

{
  /* <Autocomplete
                                options={optiondata ? optiondata : []}
                                // defaultValue={

                                //     fields.rawMaterials?.rawMaterialId ? fields.rawMaterials?.rawMaterialId.map((id) =>
                                //           console.log("object id",id)
                                //             // optiondata.find((e) => e._id === id)

                                //     ) : []
                                // }
                                onChange={(event, newValue) => {
                                    setFields({
                                        ...fields,
                                        rawMaterials: {
                                            ...fields.rawMaterials,
                                            rawMaterialId: newValue._id
                                        }
                                    });
                                }}

                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option, { selected }) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                        <li key={key} {...optionProps}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option?.name}
                                        </li>
                                    );
                                }}

                                style={{ width: "100%" }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Type Of Disability" placeholder="" />
                                )}
                            /> */
}

{
  /* <Box mt={2} width={"100%"} sx={{border: "1px solid #255766", borderRadius: "5px",}}> 
                            <Select
                                sx={{ width: "99%", height: "8vh", backgroundColor: "white", color:"black" ,borderRadius: "5px", padding: 3 }}
                                disableUnderline
                                placeholder="Select Country"
                                value={fields.country}
                                onChange={(e) =>
                                    setFields({
                                        ...fields,
                                        err: "",
                                        country: e.target.value,
                                    })
                                }
                                variant="standard"
                                size="small"
                            >
                                {COUNTRY_CODES.map((item) => (
                                    <MenuItem key={item.name} value={item.name} color="black">
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box> */
}
