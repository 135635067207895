import { Box, Button, ButtonGroup, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import DataTable from "../../components/tables/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../store/actions/modalAction";
import AsyncSearchBar from "../../components/inputs/AsyncSearchBar";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector";
import DashboardProductCounts from "./DashboardProductCounts";
import { USER_ROLES } from "../../utils/constants";

const DashboardListUi = ({
  columns,
  list,
  filters,
  setFilters,
  loading,
  setItemType,
  itemType,
  rawMaterial_columns,
  rawMaterialList,
  rawMaterialFilters,
  setRawMaterialFilters,
}) => {
  const user = useSelector((state) => state.user)
  return (
    <>
      <Box>
        <Paper sx={{ width: "100%", padding: 6 }}>
          <Box mb={4} display="flex" flexDirection="column">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mb: 5 }}
            >
              <Typography variant="h3" color={"#000"}>
                Dashboard
              </Typography>
            </Box>

              <DashboardProductCounts filters={filters} setFilters={setFilters} />
          </Box>

          <Paper elevation={2} sx={{ padding: "20px", marginTop: "50px" }}>
            <Box>
              <Typography variant="h4">In/Out History</Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={2}
              mb={4}
            >

{user.data.role !== USER_ROLES.PURCHASE && ( <Box mt={2}>
                <ButtonGroup disableElevation sx={{}}>
                  <Button
                    sx={{ height: "6vh" }}
                    variant={
                      itemType === "rawMaterial" ? "contained" : "outlined"
                    }
                    onClick={() => setItemType("rawMaterial")}
                  >
                    <Typography variant='h6' sx={{ fontWeight: "bold" }}> Raw Materials</Typography>
                  </Button>
                  <Button
                    sx={{ height: "6vh" }}
                    variant={itemType === "product" ? "contained" : "outlined"}
                    onClick={() => setItemType("product")}
                  >
                    <Typography variant='h6' sx={{ fontWeight: "bold" }}>Products</Typography>
                  </Button>
                </ButtonGroup>
              </Box>)}

            </Box>
            <Box
              mt={3}
              p={1}
              sx={{
                height: "40vh"

              }}
            >
              <DataTable
                key={itemType}
                columns={columns}
                rows={list?.result ? list?.result : []}
                count={list?.total ?? 0}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
              />
            </Box>
          </Paper>

          <Paper elevation={2} sx={{ padding: "20px", marginTop: "50px" }}>
            <Box>
              <Typography variant="h4">Raw Materials Stock</Typography>
            </Box>
            <Box
              mt={3}
              p={1}
              sx={{
                height: "40vh",

              }}
            >
              <DataTable
                columns={rawMaterial_columns}
                rows={rawMaterialList?.result ? rawMaterialList?.result : []}
                count={rawMaterialList?.total ?? 0}
                filters={rawMaterialFilters}
                setFilters={setRawMaterialFilters}
                loading={loading}
              />
            </Box>
          </Paper>
        </Paper>
      </Box>
    </>
  );
};

export default DashboardListUi;
