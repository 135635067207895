import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../store/actions/modalAction";
import { callApiAction } from "../../store/actions/commonAction";
import { Delete, Edit, Info } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import MessageDilog from "../../components/texts/MessageDilog";
import { DAY_WEEK_MONTH } from "../../utils/constants";
import {
  FetchProductStockByIdApi,
  deleteProductApi,
  fetchProductApi,
} from "../../apis/product.api";
import RawMaterialViewControllerProduct from "../products/RawMaterialViewControllerProduct";
import ProductListUi from "../products/ProductListUi";
import ProductionListUi from "./ProductionListUi";
import theme from "../../assets/css/theme";

const ActionComponent = memo(({ params, setParams, deleteApi }) => {
  const dispatch = useDispatch();
  const modalkey = "productupdate";
  const [loading, setLoading] = useState(false);

  // const onEdit = () => {
  //     dispatch(openModal(<CreateProductController id={params._id}
  //     />, "sm", false, modalkey))
  // }
  const onInfo = () => {
    dispatch(
      openModal(
        <RawMaterialViewControllerProduct params={params} />,
        "sm",
        false,
        "infoupdate"
      )
    );
  };

  const deleteFun = async (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await deleteApi({ id: params._id }),
        (response) => {
          setParams({});
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
    dispatch(closeModal("productidd"));
  };

  const onDelete = () => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={deleteFun}
          title="Alert!"
          modalId="productidd"
          message={`Are you sure to delete "${params.name || params.title}" ?`}
        />,
        "sm",
        false,
        "productidd"
      )
    );
  };

  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      <Tooltip title={"Raw-Materials Information"}>
        <IconButton disabled={loading} size="inherit" onClick={onInfo}>
          <Info color="info" fontSize="inherit" />
        </IconButton>
      </Tooltip>
      {/* <IconButton size="inherit" onClick={onEdit}>
            <Edit color="info" fontSize="inherit" />
        </IconButton> */}
      <IconButton disabled={loading} size="inherit" onClick={onDelete}>
        <Delete color="error" fontSize="inherit" />
      </IconButton>
    </Box>
  );
});

const ProductionListController = () => {
  const dispatch = useDispatch();
  const title = "Production Planning";
  const fetchApi = FetchProductStockByIdApi;
  const deleteApi = deleteProductApi;
  const [fields, setFields] = useState({
    quantity: null,
  });

  const Quantity = useRef();
  Quantity.current = fields.quantity;

  const columns = useMemo(() => {
    const arr = [
      {
        id: 1,
        fieldName: "",
        label: "Raw-Materials",
        align: "left",
        sort: true,
        renderValue: (params) => params?.requiredRawMaterials?.rawMaterialName,
      },
      {
        id: 2,
        fieldName: "",
        label: "Raw-Materials Quantity",
        align: "left",
        sort: false,
        renderValue: (params) => params?.requiredRawMaterials?.quantity,
      },
      {
        id: 3,
        fieldName: "",
        label: "Required Quantity",
        align: "left",
        sort: false,
        renderValue: (params) =>
          params?.requiredRawMaterials?.quantity * Quantity?.current,
      },
      {
        id: 4,
        fieldName: "",
        label: "Current Quantity",
        align: "left",
        sort: false,
        renderValue: (params) =>
          params.requiredRawMaterials?.rawMaterialId?.current_stock?.map(
            (e) => e.quantity
          ),
      },
      {
        id: 6,
        fieldName: "lead_time",
        label: "Lead Time",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => {
          if (params.requiredRawMaterials.rawMaterialId?.lead_time <= 7) {
            return `${params.requiredRawMaterials.rawMaterialId?.lead_time} ${DAY_WEEK_MONTH.DAY}`;
          } else {
            if (params.requiredRawMaterials.rawMaterialId?.lead_time % 7 == 0) {
              return `${Math.floor(
                params.requiredRawMaterials.rawMaterialId?.lead_time / 7
              )} ${DAY_WEEK_MONTH.WEEK} `;
            }
            return `${Math.floor(
              params.requiredRawMaterials.rawMaterialId?.lead_time / 7
            )} ${DAY_WEEK_MONTH.WEEK} ${
              params.requiredRawMaterials.rawMaterialId?.lead_time % 7
            } ${DAY_WEEK_MONTH.DAY}`;
          }
        },
      },
      {
        id: 5,
        fieldName: "",
        label: "Quantity Difference",
        align: "left",
        sort: false,
        renderValue: (params) => {
          const requiredQuantity =
          params?.requiredRawMaterials?.quantity * Quantity?.current || 0
          const currentStock =
          params?.requiredRawMaterials?.rawMaterialId?.current_stock?.map(
            (e) => e.quantity
          ) || 0
          const difference = currentStock - requiredQuantity;
          return (
            <Typography
              backgroundColor={difference < 0 ? "rgba(255, 0, 0, 0.7)" : ""}
              color={difference < 0 ? "white" : "black"}
              display={"flex"}
              justifyContent={"center"}
            >
              {difference ?? 0}
            </Typography>
          );
        },
      },

    ];
    return arr;
  }, [Quantity.current, dispatch]);

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["product_name"],
    sort: "createdAt",
    sortDirection: -1,
    product_id: null,
  });

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});
  const [generateTable, setGenerateTable] = useState(false);
  const fetchList = () => {
    setLoading(true);
    setGenerateTable(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  // useEffect(() => {
  //     fetchList()
  // }, [filters])

  return (
    <>
      <ProductionListUi
        title={title}
        filters={filters}
        setFilters={setFilters}
        fields={fields}
        setFields={setFields}
        loading={loading}
        list={list}
        columns={columns}
        fetchList={fetchList}
        generateTable={generateTable}
        setGenerateTable={setGenerateTable}
      />
    </>
  );
};
export default ProductionListController;