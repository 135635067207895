import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import DataTable from '../../components/tables/DataTable';
import CustomDialog from '../../components/layouts/common/CustomDialog';

const RawMaterialViewController = ({ params }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    console.log(" params?.rawMaterials?.length", params);
    const columns = useMemo(
        () => [

            {
                id: 1,
                fieldName: "rawMaterials",
                label: "Raw-Materials",
                align: "left",
                sort: true,
                renderValue: (params, setParams) => params.rawMaterialId.name
            },

            {
                id: 2,
                fieldName: "min_of_quantity",
                label: "MOQ",
                align: "left",
                sort: true,
            },

        ], [dispatch])

    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 100,
        search: "",
        searchable: [''],
        role: "",
        sort: "createdAt",
        sortDirection: -1,
    });


    return (
        <CustomDialog
            id={"infoupdateee"}
            title={"Raw-Materials Information"}
            closeText="Close"
        >
            <Box>
                <Box sx={{ minHeight: "30vh", overflow: "auto", scrollbarWidth: "none" }}>
                    <DataTable columns={columns} noPagination rows={params?.rawMaterials ? params?.rawMaterials : []} count={params?.rawMaterials?.length ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                </Box>
                <Box>
                    <Typography variant='h5'>Raw-Materials Count = {params?.rawMaterials?.length}</Typography>
                </Box>
            </Box>
        </CustomDialog>
    )
}

export default RawMaterialViewController