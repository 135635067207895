export const mode = "production"; //process.env.REACT_APP_APP_MODE;
let domain = "";

switch (mode) {
  case "ip":
    domain = "http://10.10.109.182:8000/";
    break;
  case "local":
    domain = "http://localhost:8001/";
    break;
  case "development":
    domain = "https://inventory-management-backend-development.up.railway.app/";
    break;
  case "production":
    domain = "https://inventory-management-backend-production.up.railway.app/";
    break;
  default:
    domain = "http://localhost:8000/";
}

export default {
  root: domain,
  signIn: `${domain}api/v1/auth/login`,
  resetToken: `${domain}api/v1/auth/resetToken`,
  resetPass: `${domain}api/v1/auth/reset-pass`,
  userAndSystemFetchByToken: `${domain}api/v1/user/fetch-by-token`,

  vendorcreate: `${domain}api/v1/vendor/`,
  vendorfetch: `${domain}api/v1/vendor/fetch`,
  vendordelete: `${domain}api/v1/vendor/delete`,
  vendorupdate: `${domain}api/v1/vendor/update`,
  VendorFetchById: `${domain}api/v1/vendor/by-id`,

  DepartmentCreate: `${domain}api/v1/department/`,
  DepartmentFetch: `${domain}api/v1/department/fetch`,
  DepartmentDelete: `${domain}api/v1/department/delete`,
  DepartmentUpdate: `${domain}api/v1/department/update`,
  DepartmentFetchById: `${domain}api/v1/department/by-id`,

  UserCreateApi: `${domain}api/v1/user/create`,
  UserFetchByIdApi: `${domain}api/v1/user/fetch-by-id`,
  UserUpdateApi: `${domain}api/v1/user/update`,
  UserFetchApi: `${domain}api/v1/user/fetch`,
  UserDelete: `${domain}api/v1/user/delete`,
  UserSearch: `${domain}api/v1/user/search`,
  UserResetPassword: `${domain}api/v1/user/reset-password`,
  UserUndoDelete: `${domain}api/v1/user/undo-delete`,

  RawMaterialCreate: `${domain}api/v1/rawMaterial/create`,
  RawMaterialFetch: `${domain}api/v1/rawMaterial/fetch`,
  RawMaterialById: `${domain}api/v1/rawMaterial/by-id`,
  RawMaterialDelete: `${domain}api/v1/rawMaterial/delete`,
  RawMaterialUpdate: `${domain}api/v1/rawMaterial/update`,
  RawMaterialSearch: `${domain}api/v1/rawMaterial/search`,

  ProductCreate: `${domain}api/v1/product/create`,
  ProductFetch: `${domain}api/v1/product/fetch`,
  ProductDelete: `${domain}api/v1/product/delete`,
  ProductUpdate: `${domain}api/v1/product/update`,
  ProductFetchById: `${domain}api/v1/product/by-id`,
  FetchProductStockById: `${domain}api/v1/product/stock-byid`,

  AddInOutProductStock: `${domain}api/v1/product/stock`,
  AddInOutRawMaterialStock: `${domain}api/v1/rawMaterial/in-out`,
  fetchCurrentStockByProductId: `${domain}api/v1/product/current-stock`,
  fetchCurrentStockByRawMaterialId: `${domain}api/v1/rawMaterial/current-stock`,

  TypeofSalesCreate: `${domain}api/v1/typeOfSale/`,
  TypeofSalesFetch: `${domain}api/v1/typeOfSale/fetch`,
  TypeofSalesDelete: `${domain}api/v1/typeOfSale/delete`,
  TypeofSalesUpdate: `${domain}api/v1/typeOfSale/update`,
  TypeofSalesFetchById: `${domain}api/v1/typeOfSale/by-id`,

  fileImage: `${domain}api/file/image`,
  fileFile: `${domain}api/file/file`,
  fileBase: `${domain}api/file`,

  CategoryCreate: `${domain}api/v1/category/`,
  CategoryFetch: `${domain}api/v1/category/fetch`,
  CategoryFetchById: `${domain}api/v1/category/by-id`,
  CategoryDelete: `${domain}api/v1/category/delete`,
  CategoryUpdate: `${domain}api/v1/category/update`,

  productBrandCreate: `${domain}api/v1/brand/`,
  productBrandFetch: `${domain}api/v1/brand/fetch`,
  productBrandFetchById: `${domain}api/v1/brand/by-id`,
  productBrandDelete: `${domain}api/v1/brand/delete`,
  productBrandUpdate: `${domain}api/v1/brand/update`,

  colorCreate: `${domain}api/v1/color/`,
  colorFetch: `${domain}api/v1/color/fetch`,
  colorFetchById: `${domain}api/v1/color/by-id`,
  colorDelete: `${domain}api/v1/color/delete`,
  colorUpdate: `${domain}api/v1/color/update`,

  productTypeCreate: `${domain}api/v1/type/`,
  productTypeFetch: `${domain}api/v1/type/fetch`,
  productTypeFetchById: `${domain}api/v1/type/by-id`,
  productTypeDelete: `${domain}api/v1/type/delete`,
  productTypeUpdate: `${domain}api/v1/type/update`,

  QualityCheckCreate: `${domain}api/v1/qc/create`,
  QualityCheckFetch: `${domain}api/v1/qc/fetch`,
  QualityCheckFetchQc: `${domain}api/v1/qc/fetch-qc`,
  QualityCheckUpdateCheckList: `${domain}api/v1/qc/update-checklist`,
  QualityCheckFetchBySerialNoCheckList: `${domain}api/v1/qc/by-serial-no`,

  ComponentCreate: `${domain}api/v1/component/create`,
  ComponentFetch: `${domain}api/v1/component/fetch`,
  ComponentDelete: `${domain}api/v1/component/delete`,
  ComponentUpdate: `${domain}api/v1/component/update`,
  ComponentFetchById: `${domain}api/v1/component/by-id`,

  itemTypeCreate: `${domain}api/v1/itemType/create`,
  itemTypeFetch: `${domain}api/v1/itemType/fetch`,
  itemTypeDelete: `${domain}api/v1/itemType/delete`,
  itemTypeUpdate: `${domain}api/v1/itemType/update`,
  itemTypeFetchById: `${domain}api/v1/itemType/by-id`

};
