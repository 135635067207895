import { Box, Button, ButtonGroup, IconButton, Paper, Typography } from '@mui/material'
import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import DataTable from '../../components/tables/DataTable';
import CustomDialog from '../../components/layouts/common/CustomDialog';
import { FilterTitleBox, FiltersBox } from '../../components/layouts/OneViewBox';
// import PaddingBoxInDesktop from '../../components/layouts/PaddingBoxDesktop';
// import AsyncSearchBar from '../../components/inputs/AsyncSearchBar';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../store/actions/modalAction';
import RawMaterialCreateController from './RawMaterialCreateController';
import AsyncSearchBar from '../../components/inputs/AsyncSearchBar';
import PaddingBoxInDesktop from '../../components/layouts/PaddingBoxDesktop';
import { USER_ROLES } from '../../utils/constants';


const RawMaterialMainUi = ({ columns, list, filters, setFilters, loading, categoryType, setCategoryType,  categories, onCategoryChange }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const id = 'rawMaterial';
  const onCreateBtnClick = () => {
    dispatch(openModal(<RawMaterialCreateController
    // callBack={async () => { fetchList() }}

    />, "sm", false, id))
  }

  return (
    <>
      <Box >
        <Paper  sx={{ width: "100%", padding: 6 }}>
         
          <Box mb={4} display="flex" flexDirection="column">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 3 }}
          >
            <Typography variant="h3" color={"#000"}>
              Raw Material
            </Typography>

            {user.data.role !== USER_ROLES.ACCOUNT && user.data.role !==USER_ROLES.PACKAGING_AND_DISPATCH && user.data.role !==USER_ROLES.PRODUCTION  && (   <Box mt={2} display="flex" alignItems="flex-end">
              <Button
                onClick={onCreateBtnClick}
                sx={{
                  width: "100%",
                  height: "6vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                variant="contained"
              >
                <AddIcon /> &nbsp;
                <Typography
                  variant="h5"
                  sx={{
                    display: "flex",
                  }}
                >
                  Add Raw Material
                </Typography>
              </Button>
            </Box>)}
          </Box>

       
            
        </Box>
        <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={2}
              mb={4}
            >
              <ButtonGroup disableElevation>
                <Button
                  variant={categoryType === 'all' ? "contained" : "outlined"}
                  onClick={() => onCategoryChange('all')}
                >
                  <Typography variant={"h6"} sx={{ fontWeight: "bold" }}>All</Typography>
                </Button>
                {categories.map((category) => (
                  <Button
                    key={category._id}
                    variant={categoryType === category?.name ? "contained" : "outlined"}
                    onClick={() => onCategoryChange(category.name)}
                  >
                    <Typography variant={"h6"} sx={{ fontWeight: "bold" }}>{category.name}</Typography>
                  </Button>
                ))}
              </ButtonGroup>
              <Box>
                <PaddingBoxInDesktop
                  sx={{
                    display: "flex",
                    width: "20vw",
                    flexDirection: "flex-start",
                  }}
                >
                  <AsyncSearchBar
                    fullWidth
                    title="Search By Name"
                    size="small"
                    placeholder={"Search By Name"}
                    defaultValue={filters.search}
                    onChange={(changedVal) =>
                      setFilters({ ...filters, pageNo: 1,
                        pageSize: 10, search: changedVal })
                    }
                  />
                </PaddingBoxInDesktop>
              </Box>
            </Box>

          <Box sx={{minHeight:"30vh"}}>
            <DataTable columns={columns} rows={list?.result ? list?.result : []} count={list?.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
          </Box>
        </Paper>
      </Box>


    </>
  )
}

export default RawMaterialMainUi